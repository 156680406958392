var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showModal,"content-class":_vm.dialogClass,"persistent":_vm.persistent,"width":_vm.width,"fullscreen":_vm.fullscreen,"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal(_vm.cancelBtnFunc)}}},[_c('v-card',{staticClass:"cy-modal",attrs:{"data-cy":"modal"}},[(!_vm.hideToolbar)?_c('v-card-title',{staticClass:"d-flex flex-column align-stretch pa-0"},[_c('div',{class:("cy-modal__bar " + _vm.headerColorClass)}),_c('div',{class:['pt-8 pb-6 d-flex justify-space-between', {
          'px-8': !_vm.fullscreen,
          'px-0': _vm.fullscreen,
        }]},[_c('h1',{staticClass:"h4 cy-modal__title"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]),_c('div',[_vm._t("modal-icon",function(){return [(_vm.headerIcon)?_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.headerIcon)+" ")]):_vm._e()]})],2)])]):_vm._e(),_c('v-card-text',{class:['cy-modal__content py-0', {
        'px-8': !_vm.fullscreen,
        'px-0': _vm.fullscreen,
      }],attrs:{"data-cy":"modal-content"}},[_vm._t("default")],2),(!_vm.hideActions)?_c('v-card-actions',{class:['pt-6 pb-6 d-flex justify-end', {
        'px-8': !_vm.fullscreen,
      }]},[_vm._t("modal-buttons",function(){return [_c('CyButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cancelBtnHidden),expression:"!cancelBtnHidden"}],attrs:{"disabled":_vm.cancelBtnDisabled,"icon":"close","theme":"primary","variant":"secondary","data-cy":"cancel-button"},on:{"click":function($event){return _vm.closeModal(_vm.cancelBtnFunc)}}},[_vm._v(" "+_vm._s(_vm.cancelBtnText)+" ")]),_c('CyButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.actionBtnHidden),expression:"!actionBtnHidden"}],staticClass:"ml-4",attrs:{"disabled":_vm.actionBtnDisabled,"theme":_vm.actionBtnColor,"loading":_vm.loading,"icon":_vm.actionBtnIcon,"data-cy":"submit-button"},on:{"click":function($event){return _vm.closeModal(_vm.actionBtnFunc)}}},[_vm._v(" "+_vm._s(_vm.actionBtnText)+" ")])]})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }